import {Trans} from '@lingui/macro'
import {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {
    Badge,
    Box,
    Button,
    Inline,
    Link,
    NakedButton,
    Popover,
    Skeleton,
    Stack,
    Text,
} from '@pleo-io/telescope'
import {Info} from '@pleo-io/telescope-icons'

import {dayjs} from '@product-web/shared--dates/dayjs'

import {bff} from '../../../../bff-hooks'
import * as EmptySection from '../../../../components/empty-section/empty-section'
import {GenericError} from '../../../../components/generic-error'
import TruncateText from '../../../../components/truncate-text/truncate-text'
import cat from '../../../../images/no-clients.webp'

const PAGE_SIZE = 5

type CompanyReferralsProps = {
    title: string
    description?: string
    year: 'current' | 'previous'
    status: ('draft' | 'processing' | 'pending' | 'active' | 'error')[]
    optional?: boolean
}
export const Referrals = ({title, description, year, status, optional}: CompanyReferralsProps) => {
    const [size, setSize] = useState(PAGE_SIZE)
    const {data, isLoading, isError} = bff.referrals.getReferralsList.useQuery({
        year,
        status,
    })

    if (isLoading) {
        return optional ? null : <ReferralsSkeleton />
    }

    if (isError) {
        return optional ? null : <GenericError />
    }

    const loadedReferralsList = data.referralsList.slice(0, size)
    const hasLoadedMoreCurrentReferrals = size > PAGE_SIZE
    const hasMoreCurrentReferrals = data.referralsList.length > size

    const referralsListIsEmpty = data.referralsList.length === 0

    if (referralsListIsEmpty) {
        const shouldHideEmptySection = optional || !data.hasNoReferrals
        return shouldHideEmptySection ? null : (
            <Stack mb={60} stretch>
                <Inline alignItems="center" space={12}>
                    <Text variant="large-accent" weight="semibold" as="h2">
                        {title}
                    </Text>
                    <Badge variant="info">0</Badge>
                </Inline>
                {data.isFirstPartnershipYear ? (
                    <NoReferralsSection />
                ) : (
                    <NoReferralsSectionForThisYear />
                )}
            </Stack>
        )
    }

    return (
        <Stack stretch>
            <Inline alignItems="center" space={12}>
                <Text variant="large-accent" weight="semibold" as="h2">
                    {title}
                </Text>
                <Badge variant={!status.includes('active') ? 'warning' : 'info'}>
                    {data.referralsList.length}
                </Badge>
            </Inline>
            {description && <Text color="colorContentStaticQuiet">{description}</Text>}
            <Stack space={24} mt={24} mb={60} stretch>
                {loadedReferralsList?.map((referral) => (
                    <Inline key={referral.id} as="tr" justifyContent="space-between" space={24}>
                        <Stack as="td" flex={1}>
                            <TruncateText text={referral.companyName} tooltip />
                        </Stack>
                        {referral.status === 'error' ? (
                            <Inline as="td" space={8}>
                                <Text>
                                    <Trans>Failed to process</Trans>
                                </Text>
                                <Popover>
                                    <Popover.Trigger>
                                        <NakedButton>
                                            <Info size={16} />
                                        </NakedButton>
                                    </Popover.Trigger>
                                    <Popover.Content align="end">
                                        <Popover.Arrow />
                                        <Box p={12}>
                                            <Text>
                                                <Trans>
                                                    We could not process this referral. Please
                                                    contact support.
                                                </Trans>
                                            </Text>
                                        </Box>
                                    </Popover.Content>
                                </Popover>
                            </Inline>
                        ) : (
                            <Inline as="td">
                                <Text>
                                    {!status.includes('active') && (
                                        <>
                                            <Text as="span" color="colorContentStaticQuiet">
                                                <Trans>Referred on</Trans>
                                            </Text>{' '}
                                        </>
                                    )}
                                    {dayjs(referral.referredAt).format('DD MMMM YYYY')}
                                </Text>
                            </Inline>
                        )}
                    </Inline>
                ))}
                <Inline>
                    {hasMoreCurrentReferrals ? (
                        <Button
                            variant="tertiary"
                            onClick={() => {
                                setSize(size + PAGE_SIZE)
                            }}
                        >
                            <Trans>See more</Trans>
                        </Button>
                    ) : (
                        hasLoadedMoreCurrentReferrals && (
                            <Button
                                variant="tertiary"
                                onClick={() => {
                                    setSize(PAGE_SIZE)
                                }}
                            >
                                <Trans>See less</Trans>
                            </Button>
                        )
                    )}
                </Inline>
            </Stack>
        </Stack>
    )
}

function NoReferralsSection() {
    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>No referrals yet</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        You have not referred any users yet. To get started,{' '}
                        <Link as={RouterLink} to="/partner/onboard">
                            add your first client
                        </Link>
                        .
                    </Trans>
                </EmptySection.Subtitle>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

function NoReferralsSectionForThisYear() {
    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>You have no referrals for this year</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        You have not referred any clients this year.{' '}
                        <Link as={RouterLink} to="/partner/onboard">
                            Invite new client
                        </Link>
                        .
                    </Trans>
                </EmptySection.Subtitle>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

function ReferralsSkeleton() {
    return (
        <Stack stretch>
            <Inline alignItems="center" space={12}>
                <Skeleton borderRadius={8}>
                    {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                    <Text variant="large-accent" weight="semibold" as="h2">
                        Referrals Section
                    </Text>
                </Skeleton>
                <Badge variant="info" skeleton>
                    0
                </Badge>
            </Inline>
            <Stack space={24} mt={24} mb={60} stretch>
                <ReferralItemSkeleton />
                <ReferralItemSkeleton />
                <ReferralItemSkeleton />
                <ReferralItemSkeleton />
                <ReferralItemSkeleton />
            </Stack>
        </Stack>
    )
}
function ReferralItemSkeleton() {
    return (
        <Inline justifyContent="space-between" space={24}>
            <Stack flex={1}>
                <Skeleton borderRadius={8}>
                    {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                    <Text>Referral company name</Text>
                </Skeleton>
            </Stack>
            <Inline>
                <Skeleton borderRadius={8}>
                    {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                    <Text>Referral Date</Text>
                </Skeleton>
            </Inline>
        </Inline>
    )
}
