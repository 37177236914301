import {
    choosePlanModalActioned,
    downgradeBlockedModalActioned,
} from '@product-web/shared--analytics'
import type {CpqBillingPeriodType, CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import type {PageOrigin, PageSection} from '../index.bff'

export type PricingPlansModalTrackingStep =
    | 'payment_plan'
    | 'pricing_plan'
    | 'completed_plan_changes'
    | 'recommended_plan'

// Keeping here only the types of the values used in the new modal
// so we can easily remove the deprecated ones post release
export const trackPricingPlansModalActioned = ({
    action,
    step,
    origin,
    section,
    plan,
    previousPlan,
    interval,
    sufficientBalance,
    fromLegacyPlan,
}: {
    action:
        | 'viewed'
        | 'abandoned'
        | 'back'
        | 'get_started'
        | 'completed'
        | 'done'
        | 'annual_toggle_clicked'
        | 'compare_all_plans_clicked'
        | 'contact_us_clicked'
        | 'view_all_link'
        | 'master_service_agreement_link'
        | 'data_processing_agreement_link'
        | 'privacy_policy_link'
        | 'done_wallet'
    step: PricingPlansModalTrackingStep
    origin: PageOrigin
    section?: PageSection
    plan?: CpqRatePlanType
    previousPlan?: CpqRatePlanType
    interval?: CpqBillingPeriodType
    sufficientBalance?: boolean
    fromLegacyPlan?: boolean
}) => {
    choosePlanModalActioned({
        action,
        step,
        origin,
        ...(section ? {section} : null),
        ...(plan ? {plan} : null),
        ...(previousPlan ? {previousPlan} : null),
        ...(interval ? {interval} : null),
        ...(sufficientBalance ? {sufficientBalance} : null),
        ...(fromLegacyPlan ? {fromLegacyPlan} : null),
    })
}

export const trackDowngradeBlockedModalActioned = ({
    variant,
    action,
    origin,
    section,
}: {
    variant: 'user_limit' | 'annual' | 'termed'
    action: 'viewed' | 'start_chat' | 'manage_users' | 'abandoned'
    origin: PageOrigin
    section?: PageSection
}) => {
    downgradeBlockedModalActioned({
        variant,
        action,
        origin,
        ...(section ? {section} : null),
    })
}
