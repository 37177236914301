import {useFlags} from '@product-web/shared--flags'
import {useUser} from '@product-web/shared--user'

import {bffHooks} from '../bff-hooks'

/**
 *
 * @returns {boolean} - Whether the Overdraft Awareness Flow should be visible to the Admin
 *
 * @return {true} if all of the following conditions are met:
 * - if user is an Admin
 * - if company is eligible for overdraft
 * - if feature-gate is enabled
 */

export const useOverdraftAwarenessFlow = () => {
    const {overdraftAwarenessFlow: flagEnabled} = useFlags()
    const user = useUser()

    const canCheckForOverdraftAwarenessFlow = flagEnabled && user?.role === 'owner'

    const {data} =
        bffHooks.overdraft.overdraftAwarenessFlowRouter.isCompanyEligibleForOverdraft.useQuery(
            undefined,
            {
                enabled: canCheckForOverdraftAwarenessFlow,
            },
        )

    return {
        isEligible: data?.isEligible ?? false,
        terms: data?.terms,
    }
}
