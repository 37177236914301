import {t, Trans} from '@lingui/macro'
import {Link as RouterLink, useNavigate} from 'react-router-dom'

import {Button, ButtonGroup, Modal, Stack, Text} from '@pleo-io/telescope'

import onboardingCompleted from '../images/onboarding-completed.svg'

interface Props {
    isOpen: boolean
    completedCourse?: string
    nextCourse?: string
    nextCoursePath?: string
}

export const CourseCompleteModal = ({
    isOpen,
    completedCourse,
    nextCourse,
    nextCoursePath,
}: Props) => {
    const navigate = useNavigate()
    const handleClose = () => navigate('/partner/academy')
    const ariaLabel = nextCourse ? t`Course completed` : t`All courses completed`

    return (
        <Modal aria-label={ariaLabel} isOpen={isOpen}>
            <Modal.Close onClick={handleClose} />
            <Modal.Content>
                <Stack space={24} justifyItems="center">
                    <img src={onboardingCompleted} alt="" />
                    {nextCourse ? (
                        <>
                            <Text as="h4" variant="3xlarge-accent">
                                <Trans>You've completed a course</Trans>
                            </Text>
                            <Stack justifyItems="center" mb={12}>
                                <Trans>
                                    <Text>Great work - you've finished {completedCourse}</Text>
                                    <Text>Start your next course: {nextCourse}</Text>
                                </Trans>
                            </Stack>
                            <ButtonGroup>
                                <Button variant="secondary" as={RouterLink} to="/partner/academy">
                                    <Trans>Back to academy</Trans>
                                </Button>
                                <Button
                                    variant="primary"
                                    as={RouterLink}
                                    to={`/partner/academy/${nextCoursePath}`}
                                >
                                    <Trans>Start next course</Trans>
                                </Button>
                            </ButtonGroup>
                        </>
                    ) : (
                        <>
                            <Text as="h4" variant="3xlarge-accent">
                                <Trans>You've completed all courses</Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    Got clients that would benefit with Pleo? Help them save time
                                    and money.
                                </Trans>
                            </Text>
                            <Button variant="primary" as={RouterLink} to="/partner/onboard">
                                <Trans>Onboard a client</Trans>
                            </Button>
                        </>
                    )}
                </Stack>
            </Modal.Content>
        </Modal>
    )
}
