import {t} from '@lingui/macro'
import {useState} from 'react'
import styled from 'styled-components'

import {Box, LoadingPage} from '@pleo-io/telescope'

import {defineClientContext} from '@product-web/shared--bff-client-context'
import {useFlags} from '@product-web/shared--flags'
import {useToaster} from '@product-web/shared--toaster'
import {useUser} from '@product-web/shared--user'
import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {RecommendedFlowWizard} from './components/recommended-flow-wizard'
import {RegularFlowWizard} from './components/regular-flow-wizard'
import type {PricingPlansModalTrackingStep} from './tracking'
import {trackPricingPlansModalActioned} from './tracking'

import {bff} from '../bff-hooks'
import {FullScreenDialog} from '../full-screen-dialog/full-screen-dialog'
import type {PlansData} from '../index.bff'
import type {PageOrigin, PageSection} from '../index.bff'
import {getIsLastDay} from '../manage-plan/choose-plan-container.helper'
import type {PlanUpgradeSource} from '../types/plans'

export type PricingPlanModalProps = {
    isOpen: boolean
    onDismiss: () => void
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
    dangerouslySetZIndexValue?: number
}

type Flows = 'recommended' | 'regular' | null
export type LatestTrackingPropsType = {
    step: PricingPlansModalTrackingStep
    plan: CpqRatePlanType | null
}

const DialogWrapper = styled(Box)`
    max-width: 1300px;
    margin: 0 auto;
`

export const PricingPlanModal = ({
    isOpen,
    onDismiss,
    planUpgradeSource,
    pageOrigin,
    pageSection,
    dangerouslySetZIndexValue,
}: PricingPlanModalProps) => {
    const user = useUser()
    const {showToast} = useToaster()
    const {pricing3GuidedMigration} = useFlags()

    const [currentFlow, setCurrentFlow] = useState<Flows>(null)
    const [latestTrackingProps, setLatestTrackingProps] = useState<LatestTrackingPropsType>()

    const {
        data,
        isError,
        isLoading,
        remove: revalidateQuery,
    } = bff.paywall.planFeatures.getPlansData.useQuery(
        {numOfUsers: user?.company?.numberOfUsers ?? 0, pageOrigin, pageSection},
        {
            meta: {dangerouslyPreventDefaultQueryInvalidationOnMutation: true},
            trpc: {
                context: defineClientContext({
                    skipBatch: true,
                }),
            },
            onSuccess: ({migrationRecommendation}) => {
                if (pricing3GuidedMigration && migrationRecommendation) {
                    setCurrentFlow('recommended')
                    // set inital tracking data
                    setLatestTrackingProps({
                        step: 'recommended_plan',
                        plan: migrationRecommendation.recommendedPlan as CpqRatePlanType,
                    })
                } else {
                    setCurrentFlow('regular')
                    // set inital tracking data
                    setLatestTrackingProps({step: 'pricing_plan', plan: null})
                }
            },
            onError: () => {
                handleQueryError()
            },
        },
    )

    const handleQueryError = () => {
        showToast(t`An error occurred. Please try again later or contact support.`, {
            level: 'error',
        })
        handleDismissAndRevalidate()
    }

    const handleClickComparePlans = () => {
        setCurrentFlow('regular')
    }

    const handleDismissAndRevalidate = () => {
        onDismiss()
        revalidateQuery()
    }

    const handleOnDimissWizard = () => {
        handleDismissAndRevalidate()
        setCurrentFlow(null)
        // due to onClose of modal(X button) is in this component,
        // we need to handle the abandoned action here
        if (latestTrackingProps) {
            trackPricingPlansModalActioned({
                action: 'abandoned',
                step: latestTrackingProps.step,
                origin: pageOrigin,
                section: pageSection,
                plan: latestTrackingProps.plan as CpqRatePlanType | undefined,
            })
        }
    }

    const renderWizard = () => {
        switch (currentFlow) {
            case 'recommended':
                return (
                    <RecommendedFlowWizard
                        plansData={data as PlansData}
                        onDismiss={handleDismissAndRevalidate}
                        pageOrigin={pageOrigin}
                        planUpgradeSource={planUpgradeSource}
                        pageSection={pageSection}
                        onClickComparePlans={handleClickComparePlans}
                        setLatestTrackingProps={setLatestTrackingProps}
                    />
                )
            case 'regular':
            default:
                return (
                    <RegularFlowWizard
                        plansData={data as PlansData}
                        onDismiss={() => {
                            onDismiss()
                            revalidateQuery()
                        }}
                        pageOrigin={pageOrigin}
                        planUpgradeSource={planUpgradeSource}
                        pageSection={pageSection}
                        setLatestTrackingProps={setLatestTrackingProps}
                    />
                )
        }
    }

    const trialEndDate = data?.trialEndDate ? new Date(data?.trialEndDate) : undefined
    const isTrialLastDay = getIsLastDay({trialEndDate})

    if (isError) {
        return null
    }

    return (
        <FullScreenDialog
            isOpen={isOpen}
            onClose={handleOnDimissWizard}
            dangerouslySetZIndexValue={dangerouslySetZIndexValue}
            hideCloseButton={isTrialLastDay || isLoading}
        >
            <DialogWrapper padding={32}>
                {isLoading ? <LoadingPage css={{minHeight: '100vh'}} /> : renderWizard()}
            </DialogWrapper>
        </FullScreenDialog>
    )
}
