import {t} from '@lingui/macro'
import {SkipNavLink} from '@reach/skip-nav'
import type React from 'react'
import type {PropsWithChildren} from 'react'
import {Suspense} from 'react'
import {Helmet} from 'react-helmet'
import {Outlet, useLocation} from 'react-router-dom'
import styled from 'styled-components'

import {LoadingPage} from '@pleo-io/telescope'

import {InvoiceAdoptionPrompt} from '@product-web/feature--adoption/invoice-adoption-prompt/invoice-adoption-prompt'
import {PocketAdoptionPrompt} from '@product-web/feature--adoption/pocket-adoption-prompt/pocket-adoption-prompt'
import {VismaMigrationAdoptionPrompt} from '@product-web/feature--adoption/visma-migration-adoption-prompt/visma-migration-adoption-prompt'
import {RedirectCollectionsToWalletPage} from '@product-web/feature--funds-management/credit/pleo-reserve-collections'
import {WalletFeatureAdoptionPrompts} from '@product-web/feature--funds-management/feature-addoption/wallet-feature-adoption-prompts'
import {StoreboxEmployeeModal} from '@product-web/feature--integrations'
import {AddAccount} from '@product-web/feature--multi-entity/add-account/add-account'
import {MultiEntityInfoModalController} from '@product-web/feature--multi-entity/multi-entity-info-modal/multi-entity-info-modal'
import {useSessionRefreshCheck} from '@product-web/feature--sca/sca'
import {Layout as MacroLayout} from '@product-web/feature--ui-page/page'
import {Main} from '@product-web/feature--ui-page-legacy/composition.styles'
import {useIsLoggedIn, useIsLoggedOut} from '@product-web/shared--auth--session/context'
import {
    useIsNavigationVisible,
    useNavigationWidth,
} from '@product-web/shared--navigation/navigation-context'
import Navigation from '@product-web/shared--navigation-core'
import {EndTrialModal} from '@product-web/shared--paywall/end-trial'
import Paywall from '@product-web/shared--paywall/paywall'
import {SubscriptionModal} from '@product-web/shared--paywall/subscription-paywall'
import {useLastHandleParam} from '@product-web/shared--routes/last-handle-param'
import {LegacyModalsBridge} from '@product-web/shared--shell/legacy/legacy-modals-bridge'
import {StatusPageProvider} from '@product-web/shared--status-page'
import {useEmployeeStoreboxIntegration} from '@product-web/shared--storebox'
import {Toaster} from '@product-web/shared--toaster'
import {getIsCompanyUser, useUser} from '@product-web/shared--user'
import HelpCentrePanels from '@product-web/sub-app--help-centre/help-centre-panels'

import {useEnhanceAnalytics} from './analytics'
import {useHandleOauthCallback} from './auth/oauth/use-handle-oauth-callback'
import {useHandleLoginRedirect} from './auth/session/use-handle-login-redirect'
import {useLoadAsyncScripts} from './load-async-scripts'
import {useOutdatedBrowserNotification} from './outdated-notification'
import {AccountNotificationBanner} from './paywall/account-notification-banner'

/**
 * The main component that wraps all pages of the app
 * - renders the app shell UI (navigation, notification hub, "More" widget etc.)
 * - initializes global 3rd party integrations (bug tracker, feature flags, analytics)
 * - renders basic layout for all pages
 */
export const Layout = () => {
    useLoadAsyncScripts()
    useHandleOauthCallback()
    useHandleLoginRedirect()
    useEnhanceAnalytics()
    useOutdatedBrowserNotification()
    useSessionRefreshCheck()

    const isLoggedIn = useIsLoggedIn()
    const isLoggedOut = useIsLoggedOut()
    const user = useUser()
    const isAuthRoute = useLastHandleParam('auth')

    return (
        <>
            <ToasterContainer />
            <MacroLayout>
                <SkipNavLink style={{zIndex: 99}}>{t`Skip to main content`}</SkipNavLink>

                <Navigation />

                <MainContent>
                    <StatusPageProvider>
                        <Suspense fallback={<LoadingPage />}>
                            {isLoggedIn && Boolean(user) && isAuthRoute && (
                                <AccountNotificationBanner />
                            )}
                            <Outlet />
                        </Suspense>
                    </StatusPageProvider>
                </MainContent>
            </MacroLayout>
            <HelpCentrePanels />
            <LegacyModalsBridge isLoggedOut={isLoggedOut} />
            {isLoggedIn && Boolean(user) && <ComponentsBehindAuth />}
            {isLoggedIn && Boolean(user) && getIsCompanyUser(user) && (
                <ComponentsBehindCompanyAuth />
            )}
        </>
    )
}

const ToasterContainer = () => {
    const isLoggedIn = useIsLoggedIn()
    const isNavigationVisible = useIsNavigationVisible()
    const {width: navigationWidth} = useNavigationWidth()

    return (
        <Toaster
            isLoggedIn={isLoggedIn}
            isNavigationVisible={isNavigationVisible}
            navigationWidth={navigationWidth}
        />
    )
}

/**
 * Groups components that are only accessible to authenticated users
 */
const ComponentsBehindAuth = () => {
    const {pathname} = useLocation()
    const isPartnerPortal = pathname.startsWith('/partner')

    /**
     * Partner users should be allowed to access the /partner routes without Company related features interfering with their experience.
     * When adding features to this component, consider whether they should run on the Partner Portal routes or not and place them below accordingly.
     */
    return (
        <>
            <AddAccount />
            {!isPartnerPortal && (
                <>
                    <Paywall />
                    <SubscriptionModal />
                    <RedirectCollectionsToWalletPage />
                    <PocketAdoptionPrompt />
                    <InvoiceAdoptionPrompt />
                    <VismaMigrationAdoptionPrompt />
                    <WalletFeatureAdoptionPrompts />
                    <EndTrialModal />
                </>
            )}
        </>
    )
}

/**
 * These modals are only visible on the expenses page, because they were previously
 * mounted inside the expenses page code and were moved here. We should confirm
 * with product whether that's indeed the behaviour we want/need.
 */
const ExpensesModals = () => {
    const storeboxModal = useEmployeeStoreboxIntegration()
    return storeboxModal.isModalOpen ? (
        <StoreboxEmployeeModal onClose={storeboxModal.closeModal} showRemindLater />
    ) : (
        <MultiEntityInfoModalController />
    )
}

/**
 * Groups components that are only accessible to company users
 */
const ComponentsBehindCompanyAuth = () => {
    const location = useLocation()
    const pathname = location.pathname
    const isExpensesPage = pathname.startsWith('/expenses')

    return <>{isExpensesPage && <ExpensesModals />}</>
}

/**
 * The base layout component that wraps the entire app
 * - makes the layout fill the entire page height
 * - sets up the window title management with Helmet
 */
const PLEO = 'Pleo'
const TITLE_TEMPLATE = `%s | ${PLEO}`
export const BaseLayout: React.FC<PropsWithChildren> = ({children}) => {
    return (
        <ApplicationWrapper data-testid="app-root">
            <Helmet defaultTitle={PLEO} titleTemplate={TITLE_TEMPLATE} />
            {children}
        </ApplicationWrapper>
    )
}

////////////////////////////////////////////////////////////////////////////////
//#region Styled Components

const ApplicationWrapper = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    /* TODO: Replace with scroll blocker on modals */
    .modal-active & {
        min-height: 0;
        max-height: 100vh;
        overflow: hidden;

        @media (--viewport-mobile) {
            max-height: 0;
        }
    }
`

const MainContent: React.FC<PropsWithChildren> = ({children}) => {
    const isLoggedIn = useIsLoggedIn()
    const isNavigationVisible = useIsNavigationVisible()
    const {width: navigationWidth} = useNavigationWidth()

    return (
        <Main
            id="reach-skip-nav"
            $isNavigationVisible={isNavigationVisible && isLoggedIn}
            $navigationWidth={navigationWidth}
        >
            {children}
        </Main>
    )
}

//#endregion Styled Components
////////////////////////////////////////////////////////////////////////////////
