import {Route} from 'react-router-dom'

import {SimplifyBookkeepingWorkflow} from './simplify-bookkeeping-workflow'
import {SmarterSpendManagement} from './smarter-spend-management'

export const routes = (
    <>
        <Route index element={<SmarterSpendManagement />} />
        <Route path="simplify-bookkeeping-workflow" element={<SimplifyBookkeepingWorkflow />} />
    </>
)
