import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Tag, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import PageNotFound from '@product-web/shared--routes/page-not-found/page-not-found'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {CourseSummary} from '../../components/course-summary'
import illustration from '../../images/set-sale.svg'

export const SmarterSpendManagement = () => {
    const {showSpendManagement} = useAcademyContext()

    if (!showSpendManagement) {
        return <PageNotFound />
    }

    return (
        <>
            <Helmet>
                <title>{t`Smarter spend management`}</title>
            </Helmet>
            <Page.Header>
                <Page.Backlink>
                    <Link IconLeft={ArrowLeft} to="/partner/academy" as={RouterLink}>
                        <Trans>Back</Trans>
                    </Link>
                </Page.Backlink>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Transform your practice with smarter spend management</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Inline space={16}>
                    <Tag variant="pink">
                        <Trans>Admins</Trans>
                    </Tag>
                    <Tag variant="gray">
                        <Trans>Bookkeepers</Trans>
                    </Tag>
                    <Inline alignItems="center" space={6}>
                        <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                        <Text color="colorContentStaticQuiet">
                            <Trans>23 mins</Trans>
                        </Text>
                    </Inline>
                </Inline>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main>
                    <Stack mt={24}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>What's it about?</Trans>
                        </Text>
                        <Text color="colorContentStaticQuiet">
                            <Trans>
                                Learn how automated spend management can help you streamline your
                                processes, expand your business and deliver greater value to your
                                clients.
                            </Trans>
                        </Text>
                        <CourseSummary
                            steps={[
                                t`Simplify and transform your bookkeeping workflow (6 min)`,
                                t`How streamlined spend management transforms clients' finances (9 min)`,
                                t`Unlock growth with a best-in-class Partner Programme (8 mins)`,
                            ]}
                        />
                    </Stack>
                    <Page.Actions>
                        <Button
                            aria-label={t`Let's go`}
                            variant="primary"
                            as={RouterLink}
                            to="./simplify-bookkeeping-workflow"
                        >
                            <Trans>Let's go</Trans> &rarr;
                        </Button>
                    </Page.Actions>
                </SplitContent.Main>
                <SplitContent.Right trivial>
                    <img src={illustration} alt="" css={{maxWidth: '400px'}} />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
