import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'

import {Box, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {Headset} from '@pleo-io/telescope-pictograms'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'
import type {BillingInfoCurrency, CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {BaseEntitlementsSection} from './base-entitlements-section'
import {EligibleEntitlementsSection} from './eligible-entitlements-section'

type PlanChangeSummaryProps = {
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    currentPlan: CpqRatePlanType
    companyCurrency: BillingInfoCurrency
    onChatWithUs: (planName: CpqRatePlanType) => void
    isBillingAnnual: boolean
    children?: ReactNode
    hasCashback?: boolean
    additionalEntitlements?: string[]
}

export const PlanChangeSummary = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    onChatWithUs,
    children,
    isBillingAnnual,
    hasCashback,
    additionalEntitlements,
}: PlanChangeSummaryProps) => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp})`)

    return (
        <Stack space={32}>
            <Card padding={32}>
                <BaseEntitlementsSection
                    companyCurrency={companyCurrency}
                    targetPlan={targetPlan}
                    currentPlan={currentPlan}
                    isBillingAnnual={isBillingAnnual}
                    hasCurrentSubscriptionCashback={hasCashback}
                    additionalEntitlements={additionalEntitlements}
                />
                {targetPlan.isUpgrade && (
                    <>
                        <Card.Divider />
                        <EligibleEntitlementsSection
                            currency={companyCurrency}
                            targetPlanType={targetPlan.planType}
                            isBillingAnnual={isBillingAnnual}
                            hasCurrentSubscriptionCashback={!!hasCashback}
                        />
                    </>
                )}
                <PlanChangeHelpSection
                    isMobile={isMobile}
                    onChatWithUs={() => onChatWithUs(targetPlan.planType)}
                />
            </Card>
            {children}
        </Stack>
    )
}

const PlanChangeHelpSection = ({
    isMobile,
    onChatWithUs,
}: {
    isMobile: boolean
    onChatWithUs: () => void
}) => (
    <Box css={{borderTop: tokens.borderStatic}}>
        <Inline paddingTop={24} space={16} alignItems="flex-start" css={{marginTop: 'auto'}}>
            {!isMobile && <Headset css={{width: '64px', height: '64px'}} />}
            <Box>
                <Text>
                    <Trans>Can we help?</Trans>
                </Text>
                <Text
                    variant="small-subtle"
                    css={{
                        paddingTop: tokens.spacing4,
                        paddingBottom: tokens.spacing8,
                        maxWidth: '400px',
                    }}
                >
                    <Trans>
                        If you have any questions around features, add-ons or pricing, just reach
                        out to us and we'll be happy to help.
                    </Trans>
                </Text>
                <ContactSupport chatLabel={t`Chat with us`} onClick={onChatWithUs} />
            </Box>
        </Inline>
    </Box>
)
