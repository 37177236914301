import {useState} from 'react'
import styled from 'styled-components'

import {Box, Card, IconButton, Inline, Text, tokens} from '@pleo-io/telescope'
import {Close} from '@pleo-io/telescope-icons'

import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

interface PromotionalBannerProps {
    pictogram: React.ReactNode
    onClick: () => void
    description: string
    ctaText: string
    tooltipText: string // Tooltip on hover + Aria-label for the close button
    testId: string
    onClose: () => void
}

export const NavigationPromotionalBanner = ({
    pictogram,
    onClick,
    onClose,
    description,
    ctaText,
    tooltipText,
    testId,
}: PromotionalBannerProps) => {
    const [closed, setClosed] = useState(false)
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)

    const handleClose = () => {
        onClose()
        setClosed(true)
    }

    if (closed) {
        return null
    }

    const cardContent = (
        <Inline flexDirection="column">
            <Text variant="small-subtle" color="colorContentStatic">
                {description}
            </Text>
            <CTAButton onClick={onClick} style={{color: tokens.colorContentInteractiveLink}}>
                {ctaText}
            </CTAButton>
        </Inline>
    )

    return (
        <StyledContainer data-testid={testId} $isTablet={isTablet}>
            <StyledCard $isTablet={isTablet}>
                <Inline justifyContent="space-between" paddingBottom={isTablet ? 0 : 4}>
                    <Box mr={12}>{pictogram}</Box>
                    {isTablet && cardContent}
                    <CloseButton
                        Icon={Close}
                        onClick={handleClose}
                        size="small"
                        variant="secondary"
                        tooltipProps={{content: tooltipText}}
                        aria-label={tooltipText}
                    />
                </Inline>

                {!isTablet && cardContent}
            </StyledCard>
        </StyledContainer>
    )
}

const CloseButton = styled(IconButton)`
    margin-top: -${tokens.spacing8};
    margin-right: -${tokens.spacing8};
`

const CTAButton = styled(Text)`
    cursor: pointer;
`

const StyledContainer = styled.div<{$isTablet?: boolean}>`
    z-index: ${tokens.zIndexOverlay};
    margin: ${({$isTablet}) =>
        $isTablet
            ? `-${tokens.spacing8} ${tokens.spacing48} ${tokens.spacing12} ${tokens.spacing48}`
            : `-${tokens.spacing8} ${tokens.spacing16} -${tokens.spacing24} ${tokens.spacing16}`};

    @media (max-height: ${breakpoints.smallTabletUp}) {
        display: none;
    }
`

const StyledCard = styled(Card)<{$isTablet?: boolean}>`
    padding: ${tokens.spacing16};
`
