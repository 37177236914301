import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

import CashManagementScreenWrapper from './screens/cash-managment-screen-wrapper'

const CashManagementScreen = React.lazy(
    async () => import('./screens/cash-management-screen/cash-management-screen'),
)

const AlertTab = React.lazy(async () => import('./components/tabs/alert/alert-tab'))
const AutoTopUpTab = React.lazy(async () => import('./components/tabs/auto-top-up/auto-top-up-tab'))
const BalanceHistoryTab = React.lazy(
    async () => import('./components/tabs/balance-history/balance-history-tab'),
)

const DirectDebitTab = React.lazy(
    async () => import('./components/tabs/direct-debit/direct-debit-tab'),
)
const DirectDebitCreation = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/direct-debit/direct-debit-creation/direct-debit-creation'
        ),
)

const WalletTab = React.lazy(async () => import('./components/tabs/wallet/wallet-tab'))
const OverdraftTab = React.lazy(async () => import('./components/tabs/overdraft/overdraft-tab'))
const OverdraftStatements = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/overdraft-statements/overdraft-statements'
        ),
)

const OverdraftManagement = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/overdraft-management/overdraft-management'
        ),
)
const ChangeOverdraftLimitPage = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/change-overdraft-limit/change-overdraft-limit'
        ),
)
const EditOverdraftPage = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/edit-overdraft/edit-overdraft'
        ),
)

// todo: the rest of the Cash Management route will be nested in the CashManagementScreen
//  https://linear.app/pleo/issue/WALLE-5900
export const routes = (
    <Route
        handle={{
            auth: true,
            minCompanyStatus: 'fdd',
            allowedRoles: roleSets.ownerAndBookkeeper,
        }}
        element={<CashManagementScreenWrapper />}
    >
        <Route element={<CashManagementScreen />}>
            <Route index element={<WalletTab />} />
            <Route path="alerts" element={<AlertTab />} />
            <Route path="auto-top-up" element={<AutoTopUpTab />} />
            <Route path="balance-history" element={<BalanceHistoryTab />} />
            <Route path="direct-debit" element={<DirectDebitTab />} />
            <Route path="overdraft" element={<OverdraftTab />} />
        </Route>

        <Route path="direct-debit/create" element={<DirectDebitCreation />} />
        <Route path="direct-debit/create/:product?" element={<DirectDebitCreation />} />

        <Route path="overdraft/statements" element={<OverdraftStatements />} />
        <Route path="overdraft/manage" element={<OverdraftManagement />} />
        <Route path="overdraft/manage/change-limit" element={<ChangeOverdraftLimitPage />} />
        <Route path="overdraft/activate" element={<EditOverdraftPage operation="activate" />} />
        <Route path="overdraft/renew" element={<EditOverdraftPage operation="renew" />} />
    </Route>
)
