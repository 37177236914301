import {t, Trans} from '@lingui/macro'
import type {RefObject} from 'react'
import {useEffect, useRef, useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {
    Box,
    Button,
    IconButton,
    Inline,
    Link,
    List,
    ListItem,
    Stack,
    Tabs,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {
    ArrowLeft,
    ArrowRight,
    ChevronUp,
    Clock,
    EmotionSmiley,
    Eye,
    Lightbulb,
    Rocket,
} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {SplitContent} from '../../../../components/split-content'
import {AcademyTabs} from '../../components/academy-tabs'
import {type OverviewStep, SideOverview} from '../../components/side-overview'
import {Snapshot} from '../../components/snapshot'
import {Testimonial} from '../../components/testimonial'

export const SimplifyBookkeepingWorkflow = () => {
    const [currentTab, setCurrentTab] = useState('takeaways')
    const [hasOverlap, setHasOverlap] = useState(false)
    const [showBackToTop, setShowBackToTop] = useState(false)
    const [activeSection, setActiveSection] = useState('section1')
    const isArticleTab = currentTab === 'article'

    const stickyContainerRef = useRef<HTMLDivElement>(null)
    const takeawaysRef = useRef<HTMLDivElement>(null)
    const section1Ref = useRef<HTMLDivElement>(null)
    const section2Ref = useRef<HTMLDivElement>(null)
    const section3Ref = useRef<HTMLDivElement>(null)
    const section4Ref = useRef<HTMLDivElement>(null)
    const section5Ref = useRef<HTMLDivElement>(null)
    const section6Ref = useRef<HTMLDivElement>(null)
    const section7Ref = useRef<HTMLDivElement>(null)
    const splitMainRef = useRef<HTMLDivElement>(null)

    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const surreyHillsAccountingLimited = 'Surrey Hills Accountancy Limited'
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const balticAssist = 'Baltic Assist'
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const gravitate = 'Gravitate'

    useEffect(() => {
        const handleScroll = () => {
            if (stickyContainerRef.current && takeawaysRef.current) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const takeawaysRect = takeawaysRef.current.getBoundingClientRect()
                setHasOverlap(stickyContainerRect.bottom > takeawaysRect.top)
            }

            if (stickyContainerRef.current && section1Ref.current) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const section1Rect = section1Ref.current.getBoundingClientRect()
                setHasOverlap(stickyContainerRect.bottom > section1Rect.top)
            }

            if (
                stickyContainerRef.current &&
                section2Ref.current &&
                section3Ref.current &&
                section4Ref.current &&
                section5Ref.current &&
                section6Ref.current &&
                section7Ref.current
            ) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const section2Rect = section2Ref.current.getBoundingClientRect()
                const section3Rect = section3Ref.current.getBoundingClientRect()
                const section4Rect = section4Ref.current.getBoundingClientRect()
                const section5Rect = section5Ref.current.getBoundingClientRect()
                const section6Rect = section6Ref.current.getBoundingClientRect()
                const section7Rect = section7Ref.current.getBoundingClientRect()

                const limitFromTop = stickyContainerRect.bottom + 40

                if (limitFromTop > section7Rect.top) {
                    setActiveSection('section7')
                } else if (limitFromTop > section6Rect.top) {
                    setActiveSection('section6')
                } else if (limitFromTop > section5Rect.top) {
                    setActiveSection('section5')
                } else if (limitFromTop > section4Rect.top) {
                    setActiveSection('section4')
                } else if (limitFromTop > section3Rect.top) {
                    setActiveSection('section3')
                } else if (limitFromTop > section2Rect.top) {
                    setActiveSection('section2')
                } else {
                    setActiveSection('section1')
                }
            }

            const scrollY = Math.round(window.scrollY)
            setShowBackToTop(scrollY > 40)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    })

    const scrollToSection = (sectionRef: RefObject<HTMLDivElement>) => {
        if (sectionRef.current && stickyContainerRef.current) {
            const stickyRect = stickyContainerRef.current.getBoundingClientRect()
            const targetPosition =
                stickyRect.top + sectionRef.current.offsetTop - stickyRect.bottom - 20

            window.scrollTo({top: targetPosition, behavior: 'smooth'})
        }
    }

    const overviewSteps: OverviewStep[] = [
        {
            isActive: activeSection === 'section1',
            title: t`Welcome to the Pleo Partner academy`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section1Ref)} : {}),
        },
        {
            isActive: activeSection === 'section2',
            title: t`What makes Pleo a game changer?`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section2Ref)} : {}),
        },
        {
            isActive: activeSection === 'section3',
            title: t`How Pleo helps your firm grow`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section3Ref)} : {}),
        },
        {
            isActive: activeSection === 'section4',
            title: t`How Pleo saves time on manual tasks`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section4Ref)} : {}),
        },
        {
            isActive: activeSection === 'section5',
            title: t`How Pleo improves data quality`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section5Ref)} : {}),
        },
        {
            isActive: activeSection === 'section6',
            title: t`How simplifying your spend workflows increases client satisfaction`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section6Ref)} : {}),
        },
        {
            isActive: activeSection === 'section7',
            title: t`Closing thoughts`,
            ...(isArticleTab ? {onClick: () => scrollToSection(section7Ref)} : {}),
        },
    ]

    return (
        <>
            <Helmet>
                <title>{t`Simplify and transform your bookkeeping workflow`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>Simplify and transform your bookkeeping workflow</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>14 mins</Trans>
                    </Text>
                </Inline>
                <Text as="h3" variant="xlarge-accent">
                    <Trans>Module overview</Trans>
                </Text>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4} ref={splitMainRef}>
                    <Tabs
                        value={currentTab}
                        onValueChange={(newValue) => {
                            setCurrentTab(newValue)
                            if (newValue === 'takeaways') {
                                scrollToSection(takeawaysRef)
                                setActiveSection('section1')
                                return
                            }
                            scrollToSection(section1Ref)
                        }}
                    >
                        <Text>
                            <Trans>
                                Learn why efficient spend management is critical for both you and
                                your clients and how Pleo addresses common challenges. We'll also
                                cover practical strategies to save time and enhance client
                                relationships.
                            </Trans>
                        </Text>
                        <Box mt={24}>
                            <InlineWistiaVideo
                                ariaLabel={t`Play video: Simplify bookkeeping workflow`}
                                thumbnail={{
                                    src: 'https://embed-ssl.wistia.com/deliveries/5eb9d937f3407ae6255302cca77c32a5210544f3.jpg?video_still_time=1.6',
                                }}
                                videoId="p6urjsg7zm"
                                autoPlay={false}
                                // TODO add tracking
                            />
                        </Box>
                        <StickyContainer ref={stickyContainerRef} $hasOverlap={hasOverlap}>
                            <AcademyTabs />
                        </StickyContainer>
                        <Tabs.Content value="takeaways">
                            <div ref={takeawaysRef}>
                                <Snapshot>
                                    <Snapshot.Card
                                        icon={<Lightbulb />}
                                        title={t`Spend management matters`}
                                        description={t`Manual spend management is a bottleneck for many accounting processes. Common issues include chasing receipts, inaccurate data and delays that impact your workflow.`}
                                    />
                                    <Snapshot.Card
                                        icon={<Rocket />}
                                        title={t`Streamlined systems save time`}
                                        description={t`Instead of juggling multiple systems, find time to focus on accurate, real-time data and seamless workflows.`}
                                    />
                                    <Snapshot.Card
                                        icon={<Eye />}
                                        title={t`Accurate data improves service delivery`}
                                        description={t`Confidence in data quality reduces end-of-month chaos for your team and your clients.`}
                                    />
                                    <Snapshot.Card
                                        icon={<EmotionSmiley />}
                                        title={t`Enhance client satisfaction`}
                                        description={t`When you save time for clients and reduce their manual workload, client satisfaction rates increase.`}
                                    />
                                </Snapshot>
                            </div>
                        </Tabs.Content>
                        <Tabs.Content value="article">
                            <Stack mt={24} space={16} ref={section1Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Welcome to the Pleo Partner academy</Trans>
                                </Text>

                                <Text>
                                    <Trans>
                                        In this module, we'll explore how spend management impacts
                                        accounting workflows. We'll also discover how Pleo
                                        simplifies processes, enhances data quality, and boosts
                                        client satisfaction.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>In this session you will learn:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            Why efficient spend management is critical for both you
                                            and your clients
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>How Pleo's tools address common challenges</Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            Practical strategies to save time and enhance client
                                            relationships
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>Let's dive in.</Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16} ref={section2Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>What makes Pleo a game changer?</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Pleo isn't just another expense tool. It's a complete{' '}
                                        <strong>spend management solution</strong> built to simplify
                                        and centralise the entire process for accounting partners.
                                        Here's what sets Pleo apart as a solution partners can
                                        easily integrate into their practice:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>All-in-one platform:</strong> Combines company
                                            cards, reimbursements, accounts payable and more all
                                            under the same roof — eliminating the need for multiple
                                            tools.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automation:</strong> Reduces repetitive tasks
                                            like manual data entry and reconciliation, freeing up
                                            time for the work that really matters.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Minimal learning curve:</strong> Designed to be
                                            intuitive, so clients and teams can start using it with
                                            minimal training and onboarding.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Security:</strong> Built on trusted financial
                                            partners like JP Morgan, Banking Circle, and Mastercard.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Instead of juggling multiple systems and tools, you can
                                        focus on accurate, real-time data and seamless workflows.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>Let's look at some examples of how it works.</Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16} ref={section3Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>How Pleo helps your firm grow</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Pleo doesn't just save you time — it helps you grow your
                                        business. By using Pleo, you can free up to 100 hours a year
                                        on bookkeeping just by automating manual reconciliation,
                                        giving you more capacity to advise clients and take on new
                                        business. Here's how partner Surrey Hills Accountancy
                                        transformed their workflows with Pleo's automated spend
                                        management:
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        <strong>The problem:</strong> Surrey Hills was spending too
                                        much time chasing clients for missing receipts and manually
                                        reconciling expenses. Clients often used personal cards,
                                        holding onto receipts until month-end, causing reporting
                                        delays.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        <strong>The solution:</strong> Pleo automated the entire
                                        expense process, syncing data directly with their accounting
                                        software. Follow-ups were eliminated, and reconciliation
                                        became effortless.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        <strong>The impact:</strong> Less admin, cleaner data, and
                                        happier clients with full visibility over spending. That
                                        efficiency even fuelled business growth, with{' '}
                                        <strong>
                                            referrals from happy clients driving new business.
                                        </strong>
                                    </Trans>
                                </Text>
                                <Testimonial
                                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                    author="Ben Withinshaw"
                                    companyPosition={t`Director of ${surreyHillsAccountingLimited}`}
                                    quote={t`Pleo has enabled us to gain happy clients, which in turn helps them refer other companies to join our practice. As a practice, we save a huge amount of time in-house as well as acting for our clients with Pleo. Pleo makes our lives easier and, by default, our clients' too.`}
                                    url="https://blog.pleo.io/en/partner-surrey-hills-accountancy"
                                />
                                <Text>
                                    <Trans>
                                        Think about your own clients. Which ones could benefit most
                                        from adopting an automated expense process?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16} ref={section4Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>How Pleo saves time on manual tasks</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Manual tasks — like data entry, chasing receipts, and
                                        reconciling expenses — don't just waste time; they limit
                                        your capacity for high-value services and risk errors
                                        creeping into reports.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        <strong>70% of finance leaders</strong> say their team's
                                        demanding workload is a major barrier to creating more value
                                        for clients.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>Pleo solves this with:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automated receipt capture:</strong> Clients
                                            simply snap a photo, and Pleo categorises the expense.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Real-time transaction syncing:</strong> No more
                                            chasing down missing receipts.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Streamlined approvals:</strong> Expense requests
                                            and reconciliations handled in a few clicks.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Here's how Baltic Assist reduced time spent on manual work
                                        by up to 80% with automated spend management:
                                    </Trans>
                                </Text>
                                <List>
                                    <ListItem>
                                        <Trans>
                                            <strong>The problem:</strong> Baltic Assist, a BPO
                                            serving over 500 clients, struggled with fragmented
                                            workflows and a labour-intensive expense process. Their
                                            accountants were slowed down by manual follow-ups and
                                            data entry.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>The solution:</strong> By introducing Pleo,
                                            Baltic Assist automated their expense management.
                                            Receipts were captured instantly and attached directly
                                            to transactions, while real-time syncing eliminated
                                            manual corrections.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>The impact:</strong> Time spent on manual tasks
                                            was reduced by up to 80%, improving efficiency and
                                            client satisfaction.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Testimonial
                                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                    author="Andžej Rynkevič"
                                    companyPosition={t`CEO of ${balticAssist}`}
                                    quote={t`For us, it's all about reducing our workload. Pleo helps us cut time spent on daily transactions by 60-80%. This has reduced costs and increased client satisfaction.`}
                                    url="https://blog.pleo.io/en/partner-hero-baltic-assist"
                                />
                                <Text>
                                    <Trans>
                                        How much time could you reclaim by replacing fragmented
                                        workflows?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16} ref={section5Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>How Pleo improves data quality</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Accurate, real-time data is essential for reliable reporting
                                        and informed decision-making. Yet{' '}
                                        <strong>59% of accountants</strong> make several data entry
                                        errors every month — often due to manual processes.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>Here's how Pleo ensures data accuracy:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automatic transaction categorisation:</strong>{' '}
                                            Every expense is logged and tagged accurately.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Real-time syncing:</strong> Transaction details
                                            are instantly recorded in your accounting software.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Complete data capture:</strong> Even without a
                                            receipt, Pleo logs key details like merchant,
                                            transaction amount, and spending category.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        The results speak for themselves — 79% of partners say Pleo
                                        has improved data quality and accuracy, creating confidence
                                        in financial reporting.
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16} ref={section6Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        How simplifying your spend workflows increases client
                                        satisfaction
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Simplifying your spend workflows doesn't just help you — it
                                        improves the experience for your clients too, making them
                                        feel more in control while deepening their trust in you as
                                        an advisor.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>Here are a few things clients appreciate most:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>No more month-end chaos:</strong> Expenses are
                                            captured as they happen, reducing last-minute stress.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Real-time visibility:</strong> Clients can
                                            monitor spending instantly, empowering proactive
                                            decisions.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Less admin, more growth:</strong> Automated
                                            tools help clients focus on scaling their business, not
                                            chasing receipts.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Gravitate, a digital accounting agency, now helps clients
                                        save up to four days per month by using Pleo.
                                    </Trans>
                                </Text>
                                <List>
                                    <ListItem>
                                        <Trans>
                                            <strong>The problem:</strong> For most of Gravitate's
                                            clients—SMEs hoping to scale—receipt chasing and
                                            inefficient expense processes were a constant
                                            frustration.
                                        </Trans>
                                    </ListItem>
                                    <Trans>
                                        <strong>The solution:</strong> By introducing Pleo,
                                        Gravitate helped clients eliminate these frustrations. With
                                        real-time expense tracking, automatic reconciliation, and
                                        simple onboarding, everyone—from warehouse workers to sales
                                        teams—found the platform easy to use. Pleo even integrates
                                        directly with Xero, keeping everything synced without extra
                                        work.
                                    </Trans>
                                    <ListItem>
                                        <Trans>
                                            <strong>The impact:</strong> Clients now save three to
                                            four days per month on manual tasks, freeing up time for
                                            more valuable work.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Testimonial
                                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                    author="Jonathan Carr"
                                    companyPosition={t`Co-founder of ${gravitate}`}
                                    quote={t`Pleo is helping to save people three or four days per month, which is pretty impressive. It's all about improving the quality of our clients' teams and freeing up time for more important work.`}
                                    url="https://blog.pleo.io/en/gravitate-partner-case-study"
                                />
                            </Stack>

                            <Stack mt={24} space={16} ref={section7Ref}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Closing Thoughts</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Empower your business and clients with Pleo, making expense
                                        management simpler, faster, and more effective.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>By simplifying spend management, Pleo helps you:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Save time</strong> on manual bookkeeping tasks.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Improve data accuracy</strong> for better
                                            reporting and compliance.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Increase client satisfaction</strong> by
                                            reducing admin and empowering better decision-making.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Ready to see what's next? Let's dive into Module 2 and
                                        explore Pleo's impact on your clients in more depth.
                                    </Trans>
                                </Text>
                            </Stack>
                        </Tabs.Content>
                    </Tabs>

                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="./" // link to next lesson
                            IconRight={ArrowRight}
                        >
                            <Trans>Next</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SplitContent.Sticky>
                        <SideOverview overviewSteps={overviewSteps} />
                    </SplitContent.Sticky>
                </SplitContent.Right>
            </SplitContent>
            {showBackToTop && (
                <IconButton
                    aria-label={t`Back to top`}
                    tooltipProps={{content: t`Back to top`}}
                    variant="secondary"
                    Icon={ChevronUp}
                    onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    css={{
                        position: 'fixed',
                        bottom: 40,
                        left: (splitMainRef.current?.getBoundingClientRect()?.right ?? 0) + 80,
                        padding: tokens.spacing24,
                        border: tokens.borderInteractive,
                    }}
                />
            )}
        </>
    )
}

const StickyContainer = styled(Box).attrs({mb: 24})<{$hasOverlap: boolean}>`
    position: sticky;
    top: 0;
    padding-top: ${tokens.spacing20};
    background-color: ${tokens.colorBackgroundInteractive};
    transition: box-shadow ${tokens.smoothInOut};

    ${({$hasOverlap}) => $hasOverlap && `box-shadow: ${tokens.shadowElevate};`}
`
