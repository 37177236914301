import type {MetaUser} from '@grafana/faro-react'
import {
    createReactRouterV6DataOptions,
    faro,
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration,
} from '@grafana/faro-react'
import {TracingInstrumentation} from '@grafana/faro-web-tracing'
import {useEffect} from 'react'
import {matchRoutes} from 'react-router-dom'

import {useTokenData} from '@product-web/shared--auth--session/context'
import config from '@product-web/shared--config'
import {useCurrentSubApp} from '@product-web/shared--routes/use-current-sub-app'

const API_URL_REGEX_STRING = 'https://api(?!.insights)[^/?#]*.pleo.io/'
const AUTH_URL_REGEX_STRING = 'https://auth[^/?#]*.pleo.io/'
const LAMBDA_URL_REGEX_STRING = 'https://[^/?#]*.lambda-url.[^/?#]*.on.aws/'
const URL_REGEX_STRING = `(${API_URL_REGEX_STRING}|${AUTH_URL_REGEX_STRING}|${LAMBDA_URL_REGEX_STRING})`

const DEFAULT_SERVICE = 'browser-product-web'

export function initGrafanaFaro() {
    initializeFaro({
        url: config.grafana.url,

        app: {
            name: 'product-web',
            namespace: 'browser',
            release: config.version,
            environment: config.environment,
        },

        ignoreUrls: [new RegExp(`^(?!.*${URL_REGEX_STRING}).*`)],

        sessionTracking: {
            samplingRate: window.Cypress ? 0 : 1,
        },

        instrumentations: [
            // Mandatory, omits default instrumentations otherwise.
            ...getWebInstrumentations(),
            new ReactIntegration({
                router: createReactRouterV6DataOptions({
                    matchRoutes,
                }),
            }),

            // Tracing package to get end-to-end visibility for HTTP requests.
            new TracingInstrumentation({
                instrumentationOptions: {
                    propagateTraceHeaderCorsUrls: [new RegExp(`^${URL_REGEX_STRING}`)],
                },
            }),
        ],
    })
}

export function useEnhancedGrafanaFaro() {
    const subApp = useCurrentSubApp()
    const tokenData = useTokenData()
    const userId = tokenData?.user.id
    const companyId = tokenData?.user.cmp ?? undefined

    useEffect(() => {
        if (userId) {
            const userMeta: MetaUser = {id: userId, attributes: {}}

            if (companyId !== undefined) {
                userMeta.attributes = {'company-id': companyId}
            }

            faro.api.setUser({id: userId})
        } else {
            faro.api.resetUser()
        }
    }, [userId, companyId])

    useEffect(() => {
        faro.api.setView({name: subApp ? `${DEFAULT_SERVICE}-${subApp}` : DEFAULT_SERVICE})
    }, [subApp])
}
