import {t, Trans} from '@lingui/macro'
import React, {useState} from 'react'

import {Link, ModalSplit, theme, useModalSplitSteps} from '@pleo-io/telescope'
import {Loading} from '@pleo-io/telescope'

import {useFlags} from '@product-web/shared--flags'
import {useActionWithFeedback} from '@product-web/shared--use-action-with-feedback/use-action-with-feedback'

import {bffHooks} from '../../../bff-hooks'
import photo1 from '../images/step1.webp'
import photo2 from '../images/step2.webp'
import photo4 from '../images/step4.webp'
import vendorCardsAnimation from '../images/vendor-cards-animation.gif'
import {
    type AnnouncementSteps,
    startSpendActivationSetup,
    trackAnnouncementStepViewed,
} from '../tracking.helpers'

export const RECURRING_SPEND_CARD_KEY = '@pleo/adoption/recurring-expenses-card-dismissed'
export const RECURRING_SPEND_ANNOUNCEMENT_MODAL_KEY = '@pleo/spend-guide-announcement-modal'

export interface RecurringSpendAnnouncementProps {
    isOpen: boolean
    setShowModal: (show: boolean) => void
    goToGuide: () => void
}

export type AnouncementStepsContentType = {
    stepId: AnnouncementSteps
    title: string
    text: string
    onClickPrev?: () => void
    onClickNext?: () => void
    nextButtonLabel: string
    illustration: string
    illustrationBgColor: string
}

export const RecurringSpendAnnouncement = ({
    isOpen,
    setShowModal,
    goToGuide,
}: RecurringSpendAnnouncementProps) => {
    const steps = [
        {stepId: 'manage-spend'},
        {stepId: 'money-aside'},
        {stepId: 'vendor-cards'},
        {stepId: 'setup'},
    ]
    const [sendingReminder, setSendingReminder] = useState(false)

    const {spendActivationGuideFooterLink} = useFlags()

    const {activeStep, setActiveStep, nextStep, previousStep} = useModalSplitSteps({
        steps,
    })

    const handleClose = () => {
        setShowModal(false)
    }
    const {mutateAsync: sendReminder} =
        bffHooks.recurringVendors.spendActivationGuide.progress.sendGuideEmailReminder.useMutation()

    const sendEmailReminder = useActionWithFeedback(
        async () => {
            setSendingReminder(true)
            await sendReminder()
            setSendingReminder(false)
        },
        () => ({
            successMessage: t`Reminder sent successfully.`,
            errorMessage: t`Failed to send reminder. Try again shortly`,
        }),
    )

    React.useEffect(() => {
        trackAnnouncementStepViewed({step: activeStep as AnnouncementSteps})
    }, [activeStep])

    const announcementStepsContent: AnouncementStepsContentType[] = [
        {
            stepId: 'manage-spend',
            title: t`Activate smart features for your recurring expenses`,
            text: t`Save time on administrative tasks and cut down interruptions to business operations by using sub-wallets and vendors cards.`,
            onClickPrev: undefined,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            illustration: photo1,
            illustrationBgColor: theme.colors.shade200,
        },
        {
            stepId: 'money-aside',
            title: t`Dedicated sub-wallets keep your funds organised`,
            text: t`Create a sub-wallet for each department or service to protect essential expenses and avoid overspending.`,
            onClickPrev: previousStep,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            illustration: photo2,
            illustrationBgColor: theme.colors.shade200,
        },
        {
            stepId: 'vendor-cards',
            title: t`Vendor cards keep recurring expenses on track`,
            text: t`Eliminate ad hoc spending on personal cards and create vendor cards for subscriptions and digital ads. Plus, automated data entry makes bookkeeping easier.`,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            onClickPrev: previousStep,
            illustration: vendorCardsAnimation,
            illustrationBgColor: theme.colors.shade200,
        },
        {
            stepId: 'setup',
            title: t`Take a few minutes to save more time in the future `,
            text: t`Follow just a few easy steps to activate these features and their benefits. `,
            onClickPrev: previousStep,
            onClickNext: () => {
                handleClose()
                // localStorage.setItem(RECURRING_SPEND_CARD_KEY, 'dismissed')
                startSpendActivationSetup({call_to_action: 'start_setup'})
                goToGuide()
            },
            nextButtonLabel: t`Set up now`,
            illustration: photo4,
            illustrationBgColor: theme.colors.shade200,
        },
    ]
    const stepBgColor =
        announcementStepsContent[steps.findIndex((step) => step.stepId === activeStep)]
            .illustrationBgColor

    return (
        <ModalSplit
            isOpen={isOpen}
            onDismiss={handleClose}
            data-testid="announcement-modal-container"
        >
            <ModalSplit.Content>
                <ModalSplit.StepIndicator
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={setActiveStep}
                />
                <ModalSplit.Steps activeStepId={activeStep}>
                    {announcementStepsContent.map((step) => {
                        return (
                            <ModalSplit.Step stepId={step.stepId} key={step.stepId}>
                                <ModalSplit.Body>
                                    {/* TO BE enabled later */}
                                    {/* {activeStep === 'manage-spend' && (
                                        <PlanTag variant="green">
                                            <Trans>Included in your plan</Trans>
                                        </PlanTag>
                                    )} */}
                                    <ModalSplit.Title>{step.title}</ModalSplit.Title>
                                    <ModalSplit.Text>{step.text}</ModalSplit.Text>
                                </ModalSplit.Body>
                                <ModalSplit.Actions>
                                    {step.onClickPrev && (
                                        <ModalSplit.BackButton onClick={step.onClickPrev} />
                                    )}
                                    {step.onClickNext && (
                                        <ModalSplit.NextButton onClick={step.onClickNext}>
                                            {step.nextButtonLabel}
                                        </ModalSplit.NextButton>
                                    )}
                                </ModalSplit.Actions>
                            </ModalSplit.Step>
                        )
                    })}
                </ModalSplit.Steps>

                {spendActivationGuideFooterLink && (
                    <ModalSplit.Footer>
                        <Trans>Want to do it later?</Trans>
                        {sendingReminder ? (
                            <Loading ml={10} size={6} />
                        ) : (
                            <Link
                                onClick={sendEmailReminder}
                                data-testid="send-email-reminder-link"
                            >
                                {' '}
                                <Trans>Send email reminder</Trans>
                            </Link>
                        )}
                    </ModalSplit.Footer>
                )}
            </ModalSplit.Content>

            {announcementStepsContent.map((step) => {
                return activeStep === 'vendor-cards' && step.stepId === 'vendor-cards' ? (
                    <ModalSplit.IllustrationContainer
                        key={'illustration-' + step.stepId}
                        style={{
                            backgroundColor: stepBgColor,
                        }}
                        data-testid="announcement-modal-illustration-container"
                    >
                        <ModalSplit.Steps activeStepId={activeStep}>
                            <ModalSplit.Step stepId={step.stepId}>
                                <ModalSplit.Illustration>
                                    <img src={step.illustration} alt="" />
                                </ModalSplit.Illustration>
                            </ModalSplit.Step>
                        </ModalSplit.Steps>
                    </ModalSplit.IllustrationContainer>
                ) : (
                    <ModalSplit.Steps activeStepId={activeStep} key={'photo-' + step.stepId}>
                        <ModalSplit.Step stepId={step.stepId}>
                            <ModalSplit.Photograph>
                                <img src={step.illustration} alt="" />
                            </ModalSplit.Photograph>
                        </ModalSplit.Step>
                    </ModalSplit.Steps>
                )
            })}
        </ModalSplit>
    )
}
