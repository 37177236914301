import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {useState} from 'react'

import {Button, Inline} from '@pleo-io/telescope'
import {Bars, Calculator, ShieldLock} from '@pleo-io/telescope-icons'
import {
    AutoTopUp,
    BookAlert,
    CardLimits,
    Cards,
    Collab,
    Detail,
    Explore,
    Lighthouse,
    Mobile,
    MoneyPiggie,
    MoneyTransfer,
    Profile,
    Referral,
    Smiles,
    Sms,
    SpendTimeBetter,
    Tags,
    Wand,
} from '@pleo-io/telescope-pictograms'

import {CardComparisonModal} from '@product-web/feature--cards/cards-comparison-modal/card-comparison-modal'
import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {PlanUpgradeSource} from '@product-web/shared--paywall/types/plans'
import type {SubscriptionRatePlanType} from '@shared/bff--moons/generated/beyond'

import type {LandingPageKeyCardProps} from './components/landing-page-key-card'

export const VendorCardsActionSection = () => {
    const [openComparisonModal, setOpenComparisonModal] = useState(false)
    return (
        <>
            <Inline>
                <Button variant="tertiary" onClick={() => setOpenComparisonModal(true)}>
                    <Trans>Explore all Pleo cards</Trans>
                </Button>
            </Inline>
            <CardComparisonModal
                isOpen={openComparisonModal}
                onDismiss={() => setOpenComparisonModal(false)}
            />
        </>
    )
}

const VendorCardsConfig: LandingPageConfig = {
    requiredPlan: 'ADVANCED',
    planUpdateSource: PlanUpgradeSource.VENDOR_CARDS_EMPTYSTATE_FLOW,
    getCtaCopy: () => t`Get vendor cards`,
    getTitle: () => t`Dedicated cards for subscriptions and digital services`,
    cards: [
        {
            imageIcon: <CardLimits size="64" />,
            getTitle: () => t`Lock in high-priority spend`,
            getDescription: () =>
                t`Easily distinguish between employee and team budgets with unlimited cards.`,
        },
        {
            imageIcon: <Referral size="64" />,
            getTitle: () => t`Switch out cardholders`,
            getDescription: () =>
                t`No disruptions if your team changes. Transfer ownership with a click of a button.`,
        },
        {
            imageIcon: <BookAlert size="64" />,
            getTitle: () => t`Accelerate bookkeeping`,
            getDescription: () =>
                t`Assign accounts, tax codes, and cost centres in advance. Just set and forget.`,
        },
    ],
    bottomAction: <VendorCardsActionSection />,
}

const PopularVendorIcons = [
    <ShieldLock size={24} key="shield-lock" />,
    <Calculator size={24} key="calculator" />,
    <Bars size={24} key="bars" />,
]

interface LandingPageConfig {
    // TODO: Remove/adjust this 'FREE' option as it's no longer a supported plan and Beyond won't return it
    requiredPlan: SubscriptionRatePlanType | 'FREE'
    planUpdateSource: PlanUpgradeSource
    getCtaCopy: () => string
    cards: LandingPageKeyCardProps[]
    bottomAction?: ReactNode
    getTitle: () => string
    getSubtitle?: () => string
}

export const LandingPagesConfig: Partial<Record<PageOrigin, LandingPageConfig>> = {
    guidelines: {
        requiredPlan: 'BEYOND',
        planUpdateSource: PlanUpgradeSource.SPENDING_GUIDELINES,
        getCtaCopy: () => t`Get Spend Guidelines`,
        getTitle: () => t`Shape your company's spending habits`,
        cards: [
            {
                imageIcon: <Wand size="64" />,
                getTitle: () => t`Generate with AI`,
                getDescription: () =>
                    t`No need to take hours writing with AI guidelines tailored to your company.`,
            },
            {
                imageIcon: <Lighthouse size="64" />,
                getTitle: () => t`Guide employee spending`,
                getDescription: () =>
                    t`Spend less time explaining and reduce grey areas for inappropriate spending.`,
            },
            {
                imageIcon: <Smiles size="64" />,
                getTitle: () => t`Share in real time`,
                getDescription: () =>
                    t`Up-to-date guidelines available for employees to check on the Pleo app.`,
            },
        ],
    },
    'vendor-cards': VendorCardsConfig,
    'popular-vendor-cards': {
        ...VendorCardsConfig,
        cards: VendorCardsConfig.cards.map((card, index) => ({
            ...card,
            imageIcon: PopularVendorIcons[index],
        })),
    },
    'recurring-vendors': {
        requiredPlan: 'ESSENTIAL',
        planUpdateSource: PlanUpgradeSource.SUBSCRIPTIONS_EMPTYSTATE_FLOW,
        getCtaCopy: () => t`Get recurring vendors`,
        getTitle: () => t`Track down subscriptions paid with card`,
        cards: [
            {
                imageIcon: <Cards size="64" />,
                getTitle: () => t`Group recurring payments`,
                getDescription: () => t`View payments to the same company or supplier.`,
            },
            {
                imageIcon: <Detail size="64" />,
                getTitle: () => t`See your latest bills`,
                getDescription: () =>
                    t`Keep track of variable payments and make sure bills are paid.`,
            },
            {
                imageIcon: <AutoTopUp size="64" />,
                getTitle: () => t`Organise your spending`,
                getDescription: () => t`Quickly locate duplicates and avoid being overcharged.`,
            },
        ],
    },
    reimbursements: {
        requiredPlan: 'ESSENTIAL',
        planUpdateSource: PlanUpgradeSource.POCKET_EMPTYSTATE_FLOW,
        getCtaCopy: () => t`Get reimbursements`,
        getTitle: () => t`Settle out-of-pocket expenses`,
        cards: [
            {
                imageIcon: <MoneyPiggie size="64" />,
                getTitle: () => t`Track any expense in Pleo`,
                getDescription: () =>
                    t`Add expenses with alternative payment methods, like cash or non-Pleo cards.`,
            },
            {
                imageIcon: <MoneyTransfer size="64" />,
                getTitle: () => t`Settle at any time`,
                getDescription: () =>
                    t`No need to wait for payday. Reimburse your team at any time.`,
            },
            {
                imageIcon: <Mobile size="64" />,
                getTitle: () => t`Communicate in the app`,
                getDescription: () =>
                    t`Keep employees and reviewers updated on the status of reimbursements.`,
            },
        ],
    },
    budgets: {
        requiredPlan: 'ADVANCED',
        planUpdateSource: PlanUpgradeSource.BUDGETS_EMPTYSTATE_FLOW,
        getCtaCopy: () => t`Get Budgets`,
        getTitle: () => t`Keep spending on track with budgets`,
        cards: [
            {
                imageIcon: <SpendTimeBetter size="64" />,
                getTitle: () => t`Create a budget for anything`,
                getDescription: () =>
                    t`Tag spending towards an upcoming event, project, or campaign.`,
            },
            {
                imageIcon: <Sms size="64" />,
                getTitle: () => t`Get real-time updates`,
                getDescription: () =>
                    t`View transactions instantly and get notified at 75% and 100% spent.`,
            },
            {
                imageIcon: <Smiles size="64" />,
                getTitle: () => t`Set clear guidelines`,
                getDescription: () =>
                    t`Share responsibility with budget owners and keep teams accountable.`,
            },
        ],
    },
    'tag-review': {
        requiredPlan: 'ADVANCED',
        planUpdateSource: PlanUpgradeSource.TAG_REVIEW_ONBOARDING_FLOW,
        getCtaCopy: () => t`Get tag review`,
        getTitle: () => t`Oversee spending across all your cost centres `,
        cards: [
            {
                imageIcon: <Tags size="64" />,
                getTitle: () => t`Assign reviewers to Tags`,
                getDescription: () =>
                    t`Get the right people to approve specific expenses for any tags created`,
            },
            {
                imageIcon: <Explore size="64" />,
                getTitle: () => t`Real time visibility`,
                getDescription: () =>
                    t`Give reviewers the oversight to monitor tagged expenses instantly`,
            },
            {
                imageIcon: <Collab size="64" />,
                getTitle: () => t`Multi level review`,
                getDescription: () =>
                    t`Combine tag and company reviews to create a thorough, two-step approval process`,
            },
        ],
    },
    'activate-invoices': {
        requiredPlan: 'FREE',
        planUpdateSource: PlanUpgradeSource.ACTIVATE_INVOICES_FLOW,
        getCtaCopy: () => t`Activate invoices`,
        getTitle: () => t`Manage and pay supplier invoices`,
        cards: [
            {
                imageIcon: <Wand size="64" />,
                getTitle: () => t`Skip the form-filling`,
                getDescription: () =>
                    t`Automatically process invoices with the help of OCR. No typing required.`,
            },
            {
                imageIcon: <Profile size="64" />,
                getTitle: () => t`Frictionless invoice approvals`,
                getDescription: () =>
                    t`Automatically request approvals from the right decision-makers.`,
            },
            {
                imageIcon: <MoneyTransfer size="64" />,
                getTitle: () => t`Plan your upcoming payments`,
                getDescription: () => t`Schedule payments for a set date or pending approval.`,
            },
        ],
    },
}
