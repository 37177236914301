import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Badge, Box, Button, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {Bank, CashSingle, Check, Close} from '@pleo-io/telescope-icons'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import {SupportedLanguage} from '@product-web/shared--i18n'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'
import type {BillingInfoCurrency} from '@shared/bff--moons/generated/beyond'
import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {formatMinorNumberToCurrency} from './currency-formatter'
import {
    getPlanContentConfiguration,
    isOverdraftAvailable,
    renderUpToCashbackLine,
    renderUpToCreditLine,
} from './pricing-plans.helper'

type PlanPricing = {
    planName: CpqRatePlanType
    MONTH: number
    YEAR: number
}

type Entitlement = {
    text: string
    included: boolean
    colorOverride?: 'colorContentDiscover'
}

type PlanColumnProps = {
    currentPlan: CpqRatePlanType | null
    onSelectPlan: () => void
    availablePlanPricing: PlanPricing
    discountEnabled: boolean
    currency: BillingInfoCurrency
    canSelectPlan: boolean
    selectButtonVariant?: 'primary' | 'secondary'
    isLegacyPlan: boolean
    showRecommendedBadge?: boolean
    additionalEntitlements?: string[]
    entitlementsOverride?: Entitlement[]
} & (
    | {showContactUs?: true; onContactUs: (planName: CpqRatePlanType) => void}
    | {showContactUs?: false; onContactUs?: never}
)

const CurrentPlanBadge = styled(Badge)`
    height: 40px;
    border-radius: 0;
    justify-content: center;
    width: 100%;
    font-size: ${tokens.fontMedium};
`

export const PlanColumn = ({
    currentPlan,
    onSelectPlan,
    onContactUs,
    availablePlanPricing,
    discountEnabled,
    currency,
    canSelectPlan,
    selectButtonVariant = 'primary',
    isLegacyPlan,
    showRecommendedBadge,
    additionalEntitlements,
    entitlementsOverride,
    showContactUs = false,
}: PlanColumnProps) => {
    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)
    const planConfiguration = getPlanContentConfiguration(availablePlanPricing.planName)
    // Combine additional entitlements and entitlements override or plan configuration entitlements
    const additionalEntitlementItems =
        additionalEntitlements?.map((entitlement) => ({
            text: entitlement,
            included: true,
            colorOverride: 'colorContentDiscover',
        })) ?? []

    const defaultEntitlementItems = entitlementsOverride
        ? entitlementsOverride
        : planConfiguration?.uniqueEntitlements.map((entitlement) => ({
              text: entitlement,
              included: true,
          })) ?? []
    // Order is important, additional entitlements should be displayed first
    const entitlements = [...additionalEntitlementItems, ...defaultEntitlementItems]
    const isOverdraft = isOverdraftAvailable(currency)
    const columnPlanName = availablePlanPricing.planName

    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    const planPrice = formatMinorNumberToCurrency({
        value: discountEnabled ? (availablePlanPricing.YEAR ?? 0) / 12 : availablePlanPricing.MONTH,
        options: {currency},
        locale,
    })
    const isCurrentPlan = currentPlan === columnPlanName && !isLegacyPlan
    const isYearlyPlan = discountEnabled && columnPlanName !== 'STARTER'
    const hasCashback = planConfiguration?.cashback && isYearlyPlan
    // The description should have a minimum height to align the buttons horizontally
    // when one of the plans has a longer description.
    const descriptionMinHeight = isSmallScreen ? '0' : '67px'

    return (
        <Card
            style={{...(showRecommendedBadge && {border: tokens.borderDiscover})}}
            padding={24}
            data-testid={`plan-column${isCurrentPlan ? '-current' : ''}`}
        >
            <Stack space={24} stretch>
                <Stack space={8}>
                    <Inline space={8} alignY="center" wrap>
                        <Text variant="2xlarge-accent" weight="regular">
                            {planConfiguration?.planName}
                        </Text>
                        {showRecommendedBadge && (
                            <Badge variant="discover">
                                <Trans>Recommended</Trans>
                            </Badge>
                        )}
                    </Inline>
                    <Text
                        variant="medium-default"
                        color="colorContentStaticQuiet"
                        css={{minHeight: descriptionMinHeight}}
                    >
                        {planConfiguration?.description}
                    </Text>
                </Stack>
                <Card.Divider />
                <Box>
                    <Text color="colorContentStatic">
                        <Trans>Starting at</Trans>
                    </Text>
                    <Inline alignItems="baseline" space={4} css={{minWidth: 0}} wrap>
                        {discountEnabled && columnPlanName !== 'STARTER' && (
                            <Text
                                variant="3xlarge-accent"
                                color="colorContentInteractiveDisabled"
                                css={{textDecoration: 'line-through'}}
                            >
                                {formatMinorNumberToCurrency({
                                    value: availablePlanPricing.MONTH,
                                    locale,
                                })}
                            </Text>
                        )}
                        <Trans>
                            <Text variant="3xlarge-accent" weight="medium">
                                {planPrice}
                            </Text>
                            <Text variant="medium-default" color="colorContentStatic">
                                /month
                            </Text>
                        </Trans>
                    </Inline>
                </Box>

                <Stack stretch>
                    <Inline
                        space={16}
                        justifyContent="center"
                        justifyItems="stretch"
                        alignItems="center"
                        css={{width: '100%'}}
                    >
                        {isCurrentPlan ? (
                            <CurrentPlanBadge variant="positive">
                                <Trans>Current plan</Trans>
                            </CurrentPlanBadge>
                        ) : (
                            <>
                                <Button
                                    variant={selectButtonVariant}
                                    css={{width: '100%'}}
                                    disabled={!canSelectPlan}
                                    aria-label={t`Select ${planConfiguration?.planName} plan`}
                                    onClick={onSelectPlan}
                                >
                                    <Trans>Select</Trans>
                                </Button>
                                {showContactUs && planConfiguration?.contactSalesEnabled && (
                                    <ContactSupport
                                        chatLabel={t`Contact us`}
                                        css={{whiteSpace: 'nowrap'}}
                                        onClick={() => {
                                            if (onContactUs) {
                                                onContactUs(columnPlanName)
                                            }
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Inline>
                </Stack>

                <Stack stretch space={8}>
                    <Inline space={8} alignItems="flex-start">
                        {hasCashback ? (
                            <>
                                <Bank size={16} css={{marginTop: '0.2em'}} />
                                <Text>{renderUpToCashbackLine(planConfiguration.cashback!)}</Text>
                            </>
                        ) : (
                            <>
                                <Close
                                    size={16}
                                    color={tokens.colorContentStaticQuiet}
                                    css={{marginTop: '0.2em'}}
                                />{' '}
                                <Text color="colorContentStaticQuiet">
                                    <Trans>No cashback</Trans>
                                </Text>
                            </>
                        )}
                    </Inline>
                    {isOverdraft && (
                        <Inline space={8} alignItems="flex-start">
                            {planConfiguration?.overdraft ? (
                                <>
                                    <CashSingle size={16} css={{marginTop: '0.2em'}} />
                                    <Text>
                                        {renderUpToCreditLine({
                                            planConfiguration,
                                            currency,
                                            locale,
                                        })}
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Close
                                        size={16}
                                        color={tokens.colorContentStaticQuiet}
                                        css={{marginTop: '0.2em'}}
                                    />
                                    <Text color="colorContentStaticQuiet">
                                        <Trans>No credit</Trans>
                                    </Text>
                                </>
                            )}
                        </Inline>
                    )}
                </Stack>

                <Stack space={8} stretch>
                    <Text weight="medium">{planConfiguration?.subTitle}</Text>

                    {entitlements.map((entitlement) => (
                        <EntitlementItem {...entitlement} key={entitlement.text} />
                    ))}
                </Stack>
            </Stack>
        </Card>
    )
}

const EntitlementItem = ({text, included, colorOverride}: Entitlement) => (
    <Inline space={8} alignItems="center">
        {included ? (
            <Check
                size={16}
                color={colorOverride ? tokens[colorOverride] : tokens.colorContentPositive}
            />
        ) : (
            <Close size={16} color={tokens.colorContentStaticQuiet} />
        )}
        <Text
            variant="medium-default"
            color={included ? colorOverride || 'colorContentStatic' : 'colorContentStaticQuiet'}
        >
            {text}
        </Text>
    </Inline>
)
