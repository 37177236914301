import {t} from '@lingui/macro'
import {useEffect} from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Link, Modal, tokens} from '@pleo-io/telescope'
import {BirdhouseClock, Headset, Smiles} from '@pleo-io/telescope-pictograms'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import {exhaustiveCheck} from '@product-web/shared--utils'

import {trackDowngradeBlockedModalActioned} from './tracking'

import {maxAllowedUsersOnStarter} from '../constants'
import type {PageOrigin, PageSection} from '../index.bff'

export type ModalVariant =
    | 'DOWNGRADE_TO_STARTER_USER_LIMIT'
    | 'DOWNGRADE_FROM_ANNUAL'
    | 'CHANGE_FROM_CUSTOM'

type PlanChangeClarificationModal = {
    isOpen: boolean
    onDismiss: () => void
    variant: ModalVariant
    pageOrigin: PageOrigin
    pageSection?: PageSection
}

export const PlanChangeClarificationModal = ({
    isOpen,
    onDismiss,
    variant,
    pageOrigin,
    pageSection,
}: PlanChangeClarificationModal) => {
    const {illustration, title, description, ctaText} = getModalContent(variant)

    const isDowngradeToStarter = variant === 'DOWNGRADE_TO_STARTER_USER_LIMIT'

    useEffect(() => {
        trackDowngradeBlockedModalActioned({
            variant: getTrackingVariant(variant),
            action: 'viewed',
            origin: pageOrigin,
            section: pageSection,
        })
    }, [])

    const handleOnDismiss = () => {
        trackDowngradeBlockedModalActioned({
            variant: getTrackingVariant(variant),
            action: 'abandoned',
            origin: pageOrigin,
            section: pageSection,
        })
        onDismiss()
    }

    return (
        <Modal
            aria-label={t`Clarification plan change`}
            isOpen={isOpen}
            onDismiss={handleOnDismiss}
            dangerouslySetZIndexValue={tokens.zIndexPopover}
        >
            <Modal.Close onClick={handleOnDismiss} />

            <Modal.Illustration>{illustration}</Modal.Illustration>

            <Modal.Title>{title}</Modal.Title>

            <Modal.Content>{description}</Modal.Content>

            <Modal.Actions>
                {isDowngradeToStarter ? (
                    <Link
                        as={RouterLink}
                        to="/people"
                        onClick={() =>
                            trackDowngradeBlockedModalActioned({
                                variant: getTrackingVariant(variant),
                                action: 'manage_users',
                                origin: pageOrigin,
                                section: pageSection,
                            })
                        }
                    >
                        {ctaText}
                    </Link>
                ) : (
                    <ContactSupport
                        chatLabel={ctaText}
                        buttonVariant="secondary"
                        onClick={() =>
                            trackDowngradeBlockedModalActioned({
                                variant: getTrackingVariant(variant),
                                action: 'start_chat',
                                origin: pageOrigin,
                                section: pageSection,
                            })
                        }
                    />
                )}
            </Modal.Actions>
        </Modal>
    )
}

type ModalContent = {
    illustration: JSX.Element
    title: string
    description: string
    ctaText: string
}

const getModalContent = (variant: ModalVariant): ModalContent => {
    const defaultStyle = {width: '80px'}

    switch (variant) {
        case 'DOWNGRADE_TO_STARTER_USER_LIMIT':
            return {
                illustration: <Smiles css={defaultStyle} />,
                title: t`Remove users to downgrade`,
                description: t`The Starter plan allows a maximum of ${maxAllowedUsersOnStarter} users. Please review your user list and remove enough users to be able to downgrade to the Starter plan.`,
                ctaText: t`Manage users`,
            }
        case 'DOWNGRADE_FROM_ANNUAL':
            return {
                illustration: <BirdhouseClock css={defaultStyle} />,
                title: t`Contact us for annual plan downgrades`,
                description: t`Since you're on an annual plan, please contact Pleo support or your account executive to make any changes.`,
                ctaText: t`Start a chat`,
            }
        case 'CHANGE_FROM_CUSTOM':
            return {
                illustration: <Headset css={defaultStyle} />,
                title: t`Contact us for custom plan changes`,
                description: t`You're currently on a custom plan. To make any changes, contact Pleo support or your account executive, and we'll assist you.`,
                ctaText: t`Start a chat`,
            }
        default:
            return exhaustiveCheck(variant)
    }
}

export const getTrackingVariant = (variant: ModalVariant) => {
    switch (variant) {
        case 'DOWNGRADE_TO_STARTER_USER_LIMIT':
            return 'user_limit'
        case 'DOWNGRADE_FROM_ANNUAL':
            return 'annual'
        case 'CHANGE_FROM_CUSTOM':
            return 'termed'
        default:
            return exhaustiveCheck(variant)
    }
}
