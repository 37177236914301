import {t, Trans} from '@lingui/macro'

import {Tabs, tokens} from '@pleo-io/telescope'

export const AcademyTabs = () => (
    <Tabs.TriggerList
        css={{
            marginBottom: tokens.spacing36,
            justifyContent: 'center',
            borderBottom: tokens.borderInteractiveQuiet,
        }}
    >
        <Tabs.Trigger key="takeaways" aria-label={t`Takeaways Tab`} value="takeaways">
            <Trans>Takeaways</Trans>
        </Tabs.Trigger>
        <Tabs.Trigger key="article" aria-label={t`Article Tab`} value="article">
            <Trans>Article</Trans>
        </Tabs.Trigger>
    </Tabs.TriggerList>
)
