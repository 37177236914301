import {Trans} from '@lingui/macro'
import type {LottieRefCurrentProps} from 'lottie-react'
import Lottie from 'lottie-react'
import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {Inline, Stack, Tag, Text} from '@pleo-io/telescope'

import animationData from './animations/introduction-to-pleo.json'

import {ActionItem} from '../../../../components/action-item/action-item'

export const SmarterSpendManagement = () => {
    const lottieRef = React.useRef<LottieRefCurrentProps>(null)

    React.useEffect(() => {
        // The initial frames of the animation are just a gray background, so we skip them and go to the first frame with content
        lottieRef.current?.goToAndStop(6, true)
    }, [lottieRef])

    return (
        <ActionItem
            as={Link}
            onMouseEnter={() => lottieRef.current?.play()}
            onMouseLeave={() => lottieRef.current?.pause()}
            to="/partner/academy/smarter-spend-management"
        >
            <Stack>
                <Animation
                    lottieRef={lottieRef}
                    animationData={animationData}
                    autoplay={false}
                    loop
                />
                <ActionItem.Divider />
                <ActionItem.Content space={16} css={{minHeight: 220}}>
                    <Text as="h2" variant="xlarge-accent">
                        <Trans>Transform your practice with smarter spend management</Trans>
                    </Text>
                    <Text color="colorContentStaticQuiet">
                        <Trans>
                            Discover how you can free up time for your practice with Pleo (14 mins)
                        </Trans>
                    </Text>
                    <Inline space={6}>
                        <Tag variant="pink">
                            <Trans>Admins</Trans>
                        </Tag>
                        <Tag variant="gray">
                            <Trans>Bookkeepers</Trans>
                        </Tag>
                    </Inline>
                </ActionItem.Content>
            </Stack>
        </ActionItem>
    )
}

const Animation = styled(Lottie)`
    width: 100%;
    display: flex;
`
